<template>
  <b-navbar-nav class="ml-auto">
    <b-nav-text>Баланс: {{CURRENT_USER.balance}}</b-nav-text>
    <Actions />
    <b-nav-item-dropdown
        :text="CURRENT_USER.login"
        right
    >
      <b-dropdown-item><router-link :to="'/users/'+CURRENT_USER.id" @click.native="logout"><b-icon icon="person-bounding-box"></b-icon> Профиль</router-link></b-dropdown-item>
      <b-dropdown-divider></b-dropdown-divider>
      <b-dropdown-item>
        <router-link to="logout" @click.native="logout"><b-icon icon="door-open"></b-icon> Выход</router-link>
      </b-dropdown-item>
    </b-nav-item-dropdown>
  </b-navbar-nav>
<!--  <div class="navbar-collapse collapse">-->
<!--    <ul class="navbar-nav navbar-align">-->
<!--      <li class="nav-item dropdown">-->
<!--        <span class="nav-link d-sm-inline-block text-secondary">Баланс: {{CURRENT_USER.balance}}</span>-->
<!--      </li>-->
<!--    </ul>-->
<!--  </div>-->
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import Actions from "@/components/Actions";

export default {
  name: "Balance",
  components: {Actions},
  methods:{
    ...mapActions([
        'GET_CURRENT_USER'
    ])
  },
  computed: {
    ...mapGetters([
        'CURRENT_USER'
    ])
  },
  mounted() {
    this.GET_CURRENT_USER()
  }
}
</script>
<template>
  <b-card-text>
    <b-table id="my-table" :busy="isBusy"
             :current-page="currentPage"
             :fields="fields"
             :filter="filter"
             :filter-included-fields="filterOn"
             @filtered="onFiltered"
             :items="ONLINE_ITEMS"
             :per-page="perPage"
             :tbody-tr-class="rowClass"
             borderless
             hover
             outlined
             responsive="true"
             small
             striped>
      <template #table-busy>
        <div class="text-center text-danger my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong>Загрузка...</strong>
        </div>
      </template>
      <template #cell(Code.id)="data">
        <router-link :to="'/codes/'+data.item.Code.id">{{ data.item.Code.id }}</router-link>
      </template>
      <template #cell(last)="data">
        {{ formateddate(data.item.last) }}
      </template>
      <template #cell(Code.Klient.name)="data">
        <router-link :to="'/orgs/'+data.item.Code.Klient.id">{{ data.item.Code.Klient.name }}</router-link>
      </template>
      <template #cell(Code.Ecr.znm)="data">
        <router-link :to="'/ecrs/'+data.item.Code.Ecr.id">{{ data.item.Code.Ecr.znm }}</router-link>
      </template>
    </b-table>
    <b-pagination v-model="currentPage" :per-page="perPage" :total-rows="rows" align="right" aria-controls="my-table"
                  first-number
                  last-number size="sm"></b-pagination>
  </b-card-text>
</template>

<script>
import * as moment from "moment/moment";
import {mapActions, mapGetters} from "vuex";

export default {
  name: 'OnlineList',
  data() {
    return {
      isBusy: false,

      perPage: 18,
      currentPage: 1,
      totalRows: 0,

      // filter: '',
      filterOn: ['Code'],
      // filterOn: ['Ecr.znm'],
      fields: [
        {key: 'last', label: 'Когда', sortable: true, sortDirection: 'desc'},
        // {key: 'Status', label: '', sortable: true},
        {key: 'Code.id', label: '#', sortable: true,},
        {key: 'Code.Klient.name', label: 'Клиент', sortable: true},
        {key: 'Code.Ecr.Model.name', label: 'Модель', sortable: true},
        {key: 'Code.Ecr.znm', label: 'ЗНМ', sortable: true},
        {key: 'Code.Status.name', label: 'Статус'},
      ],
    }
  },
  methods: {
    ...mapActions([
      'GET_ONLINE_ITEMS'
    ]),
    rowClass(item, type) {
      if (!item || type !== 'row') return
      if (item.status === 1 || item.status === 2) return 'table-secondary'
      if (item.status === 3 || item.status === 9) return 'table-info'
      if (item.status === 5 || item.status === 8) return 'table-warning'
      if (item.status === 6) return 'table-danger'
      if (item.status === 4 || item.status === 7) return 'table-success'
    },
    formateddate: function (d) {
      if (typeof d === 'undefined') {
        return ''
      } else {
        // return moment(d, 'YYYY-MM-DD').format('DD.MM.YYYY')
        return moment(d, 'YYYY-MM-DD HH:mm:ss').format('HH:mm:ss DD.MM.YYYY')
      }
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    }
  },

  computed: {
    ...mapGetters([
      'ONLINE_ITEMS',
      'FILTER'
    ]),
    rows() {
      if (this.filter !== '') {
        return this.totalRows
      }
      return this.ONLINE_ITEMS == null ? 0 : this.ONLINE_ITEMS.length
    },
    filter: {
      get() {
        return this.FILTER;
      },
    }
  },
  cron: {
    time: 15000,
    method: 'GET_ONLINE_ITEMS'
  },
  mounted() {
    // console.log(this.currentPage)
    this.GET_ONLINE_ITEMS()
        .catch((error) => {
          if (error.response.status === 401) {
            this.$router.push('/logout')
          }
        })
  },
}</script>

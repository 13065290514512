<template>
  <b-row>
    <b-col cols="12">
      <b-card no-body>
        <template v-if="$route.params.id">
          <b-button-toolbar key-nav>
            <b-button-group class="mx-1">
              <b-button variant="outline-dark" @click="$router.back()">
                <b-icon icon="chevron-left"></b-icon>
              </b-button>
            </b-button-group>
          </b-button-toolbar>

          <b-card>
            <b-card-text>
              <b-row>
                <b-col class="mb-1" lg="12" order="1">
                  Статус: <b>{{ currentItem.Status.name }}</b>
                </b-col>
              </b-row>

              <b-row>
                <b-col class="mb-1" lg="12" order="1">
                  <b-input-group prepend="Серийный ПК">
                    <b-form-input v-model="currentItem.autokey" readonly></b-form-input>
                  </b-input-group>
                </b-col>
              </b-row>

              <template v-if="currentItem.autokey">
                <b-row>
                  <b-col class="mb-1" lg="12" order="1">
                    <b-input-group prepend="Лицензия">
                      <b-form-input v-model="getCode" readonly></b-form-input>
                    </b-input-group>
                  </b-col>
                </b-row>
              </template>

              <b-row>
                <b-col class="mb-1" lg="3" order="2">
                  Заявка: <b>{{ formateddate(currentItem.begin_date) }}</b>
                </b-col>
                <b-col class="mb-1" lg="3" order="3">
                  Оплачена: <b>{{ formateddate(currentItem.pay_date) }}</b>
                </b-col>
                <b-col class="mb-1" lg="6" order="4">
                  Действует до: <b>{{ formateddate(currentItem.end_date) }}</b>
                </b-col>
              </b-row>

              <b-row>
                <b-col class="mb-1" lg="12" order="1">
                  Организация: <b>{{ currentItem.Klient.name }}</b>
                </b-col>
              </b-row>

              <b-row>
                <b-col class="mb-1" lg="3" order="2">
                  БИН/ИИН:
                  <router-link :to="'/orgs/'+currentItem.Klient.id">{{ currentItem.Klient.bin }}</router-link>
                </b-col>
                <b-col class="mb-1" lg="3" order="3">
                  Телефон: <a :href="'tel:'+currentItem.Klient.phone">{{ currentItem.Klient.phone }}</a>
                </b-col>
                <b-col class="mb-1" lg="6" order="4">
                  Email: <a
                    :href="'mailto:'+currentItem.Klient.email+'?subject=Сервер лицензий'">{{
                    currentItem.Klient.email
                  }}</a>
                </b-col>
              </b-row>

              <ViewEcrCard
                  :ecrItem="currentItem.Ecr"
              />

              <CodeActions
                  :status="currentItem.status"
                  @active="active"
                  @block="block"
                  @reissue="reissue"
                  @revoke="revoke"
              />

            </b-card-text>
          </b-card>
        </template>

        <template v-else>
          <LicensesList/>
        </template>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import LicensesList from "@/components/Code/CodesList"
import ViewEcrCard from "@/components/Ecr/ViewEcrCard"
import CodeActions from "@/components/Code/CodeActions"
import {mapActions, mapGetters} from "vuex";
import Toasts from "@/mixins/toasts";
import * as moment from "moment/moment";
import axios from "axios";


export default {
  name: 'License',
  mixins: [Toasts],
  data() {
    return {
      code: null,
    }
  },
  props: {
    codeItem: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  components: {
    LicensesList,
    ViewEcrCard,
    CodeActions,
  },
  computed: {
    ...mapGetters([
      'CODE_ITEMS',
      'FILTER'
    ]),
    currentItem: function () {
      if (this.CODE_ITEMS) {
        for (let i = 0; i < this.CODE_ITEMS.length; i++) {
          if (this.CODE_ITEMS[i].id == this.$route.params.id) {
            // console.log("status: " + this.CODE_ITEMS[i].status)
            return this.CODE_ITEMS[i]
          }
        }
      }
      return {}
    },
  },
  asyncComputed: {
    getCode: {
      get() {
        if (this.currentItem.autokey) {
          return axios.post('/code/getL', {Code: this.currentItem.autokey})
              .then(response => {
                return response.data
              })
              .catch((error) => {
                return error
              })
        }
      },
      default() {
        return 'Loading...'
      }
    }
  },
  methods: {
    ...mapActions([
      'STATUS_ACTIVE',
      'STATUS_REISSUE',
      'STATUS_REVOKE',
      'STATUS_BLOCK',
      // 'GET_ECR_ITEMS'
    ]),
    back() {
      this.$router.replace("/codes");
    },
    active() {
      this.STATUS_ACTIVE({key: this.$route.params.id, value: ""})
          .then(response => {
            console.log(response)
            this.back()
          })
          .catch(error => {
            if (error.response.status === 401) {
              this.$router.push('/logout')
            }
            this.showError(error.message, error)
            // console.log(error)
          })
      this.back()
    },
    reissue() {
      this.STATUS_REISSUE({key: this.$route.params.id, value: ""})
          .then(response => {
            console.log(response)
            this.back()
          })
          .catch((error) => {
            if (error.response.status === 401) {
              this.$router.push('/logout')
            }
          })
    },
    revoke() {
      this.STATUS_REVOKE({key: this.$route.params.id, value: ""})
          .then(response => {
            console.log(response)
            this.back()
          })
          .catch((error) => {
            if (error.response.status === 401) {
              this.$router.push('/logout')
            }
          })
    },
    block() {
      this.STATUS_BLOCK({key: this.$route.params.id, value: ""})
          .then(response => {
            console.log(response)
            this.back()
          })
          .catch((error) => {
            if (error.response.status === 401) {
              this.$router.push('/logout')
            }
          })
    },
    formateddate: function (d) {
      if (typeof d === 'undefined') {
        return ''
      } else {
        // return moment(d, 'YYYY-MM-DD').format('DD.MM.YYYY')
        return moment(d, 'YYYY-MM-DD').format('YYYY.MM.DD')
      }
    }

  },
  mounted() {
    // if (this.codeItem.id) {
    //   this.axios.post('/api/decode', {code: this.codeItem}, {headers: auth.getAuthHeader()})
    //       .then(response => {
    //         // this.requestData = response.data
    //         this.znm = response.data.znm
    //         this.rnm = response.data.rnm
    //         this.bin = response.data.bin
    //         // console.log(response.data.bin)
    //       })
    //       .catch((error) => {
    //         this.showError('Ошибка запроса', error.message)
    //         // return Promise.reject(error);
    //       })
    // } else {
    //   // this.back()
    //   // this.$router.replace("/");
    // }
  }
}
</script>

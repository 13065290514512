import axios from "axios";
// import Toasts from "@/mixins/toasts";

const API_URL = '/user/'
const LOGIN_URL = API_URL + 'login'
const SIGNUP_URL = API_URL + 'signup'

export default {

    login(context, creds, redirect) {
        return axios.post(LOGIN_URL, creds)
            .then(response => {
                // Toasts.showError('Ошибка запроса', response.data)
                // localStorage.setItem('id_token', response.data.id_token)
                localStorage.setItem('id_token', response.data)
                if (redirect) {
                    context.$router.replace(redirect)
                }
            })
    },

    signup(context, creds, redirect) {
        return this.axios.post(SIGNUP_URL, creds)
            .then(response => {
                // localStorage.setItem('id_token', response.data.id_token)
                localStorage.setItem('id_token', response.data)
                if (redirect) {
                    context.$router.replace(redirect)
                }
            })
    },

    // logout(context) {
    //     localStorage.removeItem('id_token')
    //     context.$router.replace('/')
    //     // context.$router.reload()
    //     // location.replace("/");
    //     window.location.reload();
    // },
    logout() {
        localStorage.removeItem('id_token')
        window.location.reload();
    },

    isAuthenticated() {
        var jwt = localStorage.getItem('id_token')
        return !!jwt;

    },

    getAuthHeader() {
        return {
            'Authorization': 'Bearer ' + localStorage.getItem('id_token')
        }
    }
}

<template>
  <b-row>
    <b-col cols="12">
      <b-card no-body>
<!--        <template #header>-->
<!--          <b-button-toolbar key-nav>-->
<!--            <b-button-group v-if="$route.params.id" class="mx-1">-->
<!--              <b-button variant="outline-dark" @click="$router.back()">-->
<!--                <b-icon icon="chevron-left"></b-icon>-->
<!--              </b-button>-->
<!--            </b-button-group>-->
<!--            <b-button-group v-else class="mx-1">-->
<!--              <router-link :to="'/new'">-->
<!--                <b-button variant="outline-dark">-->
<!--                  <b-icon icon="plus"></b-icon>-->
<!--                </b-button>-->
<!--              </router-link>-->
<!--            </b-button-group>-->
<!--          </b-button-toolbar>-->
<!--        </template>-->


        <OnlineList/>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import OnlineList from "@/components/Code/OnlineList"

export default {
  name: 'Online',
  data() {
    return {
      code: null,
    }
  },
  props: {
    codeItem: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  components: {
    OnlineList,
  },
}
</script>

<template>
  <b-nav-item-dropdown right  v-if="ACTION_ITEMS">
    <template>
      <div class="position-relative" id="indicator">
        <i class="align-middle" data-feather="bell"></i>
        <span class="indicator">{{ACTION_ITEMS.length}}</span>
      </div>
    </template>

    <b-dropdown-item>
<!--      <router-link :to="'/action/'+ACTION_ITEMS.id" @click.native="logout">-->
<!--        <b-icon icon="person-bounding-box"></b-icon> Профиль-->
<!--      </router-link>-->
    </b-dropdown-item>
  </b-nav-item-dropdown>

<!--        <li class="nav-item dropdown" id="alerts">-->
<!--          <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right py-0">-->
<!--            <div class="list-group" id="listGroup">-->
<!--              {{ range $key, $value := .User.Actions}}-->
<!--              <div class="list-group-item" onclick="readed({{$value.ID}})">-->
<!--                <div class="row g-0 align-items-center">-->
<!--                  <div class="col-2">-->
<!--                    <i class="text-{{$value.Type}}" data-feather="alert-circle"></i>-->
<!--                  </div>-->

<!--                  <div class="col-10">-->
<!--                    <div class="text-dark">{{$value.Head}}</div>-->
<!--                    <div class="text-muted small mt-1">{{$value.Body}}.</div>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->

<!--              {{end}}-->
<!--            </div>-->
<!--          </div>-->
<!--        </li>-->
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "Actions",
  computed:{
    ...mapGetters([
        'ACTION_ITEMS'
    ])
  },
  methods:{
    ...mapActions([
        'GET_ACTIOIN_ITEMS'
    ])
  },
  mounted() {
    this.GET_ACTIOIN_ITEMS()
  }
}
</script>
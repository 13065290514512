import '@babel/polyfill'
import Vue from 'vue'
import './plugins/bootstrap-vue'
import './plugins/axios'
import App from './App.vue'
import router from './router'
import VueHead from 'vue-head'
import store from "./store/store"
import HighchartsVue from 'highcharts-vue'
import AsyncComputed from 'vue-async-computed'
import crono from 'vue-crono'

import '@/assets/css/app.css'


Vue.use(HighchartsVue)
Vue.use(AsyncComputed)
Vue.use(crono)
Vue.use(VueHead)

// Vue.config.productionTip = false
Vue.config.productionTip = true
// Vue.config.devtools = false

new Vue({
    store,
    router,
    render: h => h(App)
}).$mount('#app')

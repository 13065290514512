<template>
  <!--  <b-card :title="'Редактирование ['+ecrItem.id+']'">-->
  <b-card :title="'Редактирование ['+ECR_ITEM.id+']'">
    <b-card-text>
      <b-row>
        <b-col class="mb-1" lg="2" order="1">
          <b-input-group prepend="ID">
            <!--            <b-form-input v-model.number="ecrItem.id" readonly type="number"></b-form-input>-->
            <b-form-input v-model.number="ECR_ITEM.id" readonly type="number"></b-form-input>
          </b-input-group>
        </b-col>
        <b-col class="mb-1" lg="4" order="2">
          <b-input-group prepend="ЗНМ">
            <!--            <b-form-input v-model="ecrItem.znm"></b-form-input>-->
            <b-form-input v-model="ECR_ITEM.znm"></b-form-input>
            <b-input-group-append is-text>
              <b-icon icon="search"></b-icon>
            </b-input-group-append>
          </b-input-group>
        </b-col>
        <b-col class="mb-1" lg="4" order="3">
          <b-input-group prepend="РНМ">
            <!--            <b-form-input v-model="ecrItem.rnm" required type="number"></b-form-input>-->
            <b-form-input v-model="ECR_ITEM.rnm" required type="number"></b-form-input>
          </b-input-group>
        </b-col>
        <b-col class="mb-1" lg="7" order="4">
          <b-input-group prepend="Вид ПО">
            <!-- <b-form-input required v-model="ecrItem.modeltxt"></b-form-input> -->
            <b-form-select v-model="ECR_ITEM.model" :options="MODEL_ITEMS" text-field="name"
                           value-field="id"></b-form-select>
          </b-input-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="mb-1" lg="12" order="1">
          <b-input-group prepend="Клиент">
            <!--            <b-form-select v-model.number="ecrItem.klient" :options="ORG_ITEMS" text-field="name"-->
            <b-form-select v-model.number="ECR_ITEM.klient" :options="ORG_ITEMS" text-field="name"
                           value-field="id"></b-form-select>
          </b-input-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col class="mb-1" lg="12" order="1">
          <b-button variant="primary" @click="click">{{ okBtnTitle }}</b-button>
        </b-col>
      </b-row>
    </b-card-text>
  </b-card>

</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "EcrCard",
  props: {
    ecrItem: {
      type: Object,
      default() {
        return {id: 0}
      }
    },
    okBtnTitle: {
      type: String,
      default: 'Ok'
    }
  },
  computed: {
    ...mapGetters([
      'ECR_ITEM',
      'ORG_ITEMS',
      'MODEL_ITEMS'
    ]),
  },
  methods: {
    ...mapActions([
      'GET_ECR_ITEM',
      'GET_MODEL_ITEMS',
      'GET_ORG_ITEMS'
    ]),
    click: function () {
      this.$emit('clickOkBtn')
    }
  },
  mounted() {
    this.GET_MODEL_ITEMS()
    this.GET_ORG_ITEMS()
  },


  watch: {
    $route() {
      if (this.$route.params.id) {
        // console.log("!!!! this.$route.params.id " + this.$route.params.id)
        this.GET_ECR_ITEM(this.$route.params.id)
      }
    }
  }

}
</script>

import axios from "axios";
import auth from "@/auth";

export default {
    GET_CODE_ITEMS({commit}) {
        return axios.get('/api/code', {headers: auth.getAuthHeader()})
            .then(response => {
                commit('CODE_ITEMS_TO_STATE', response.data.data)
            })
    },
    GET_ONLINE_ITEMS({commit}) {
        return axios.get('/api/online', {headers: auth.getAuthHeader()})
            .then(response => {
                commit('ONLINE_ITEMS_TO_STATE', response.data.data)
            })
    },
    GET_ECR_ITEM({commit}, id) {
        return axios.get('/api/ecr/' + id, {headers: auth.getAuthHeader()})
            .then(response => {
                commit('ECR_ITEM_TO_STATE', response.data.data)
            })
    },
    GET_ECR_ITEMS({commit}, org) {
        let url = '/api/ecr'
        if (org) {
            url = '/api/ecr/orgId/' + org
        }
        return axios.get(url, {headers: auth.getAuthHeader()})
            .then(response => {
                commit('ECR_ITEMS_TO_STATE', response.data.data)
            })
    },
    GET_ORGBYBIN_ITEM({commit}, org) {
        return axios.get('/api/organisation/' + org, {headers: auth.getAuthHeader()})
            .then(response => {
                // console.log(response.data.data)
                commit('ORG_ITEM_TO_STATE', response.data.data)
            })
    },
    GET_ORG_ITEM({commit}, org) {
        return axios.get('/api/organisation/id/' + org, {headers: auth.getAuthHeader()})
            .then(response => {
                // console.log(response.data.data)
                commit('ORG_ITEM_TO_STATE', response.data.data)
            })
    },
    GET_ORG_ITEMS({commit}) {
        return axios.get('/api/organisation', {headers: auth.getAuthHeader()})
            .then(response => {
                commit('ORG_ITEMS_TO_STATE', response.data.data)
            })
    },
    GET_BALANCE_ITEMS({commit}) {
        return axios.get('/api/balance', {headers: auth.getAuthHeader()})
            .then(response => {
                commit('BALANCE_ITEMS_TO_STATE', response.data.data)
            })
    },
    GET_USER_ITEMS({commit}) {
        return axios.get('/api/user', {headers: auth.getAuthHeader()})
            .then(response => {
                commit('USER_ITEMS_TO_STATE', response.data.data)
            })
    },
    GET_MODEL_ITEMS({commit}) {
        return axios.get('/api/models', {headers: auth.getAuthHeader()})
            .then(response => {
                commit('MODEL_ITEMS_TO_STATE', response.data.data)
            })
    },
    SAVE_ORG_TO_DB(commit, org) {
        console.log('saveOrganisaton ' + org.name)
        return axios.post('/api/organisation', org, {headers: auth.getAuthHeader()})
    },
    SAVE_ECR_TO_DB(commit, ecr) {
        return axios.post('/api/ecr', ecr, {headers: auth.getAuthHeader()})
    },
    GET_COUNT_CODES({commit}) {
        axios.get('/api/count/codesOnYear', {headers: auth.getAuthHeader()})
            .then(response => {
                commit('GRAPH_DATA_TO_STATE', response.data.data)
            })

        axios.get('/api/count/codes', {headers: auth.getAuthHeader()})
            .then(response => {
                commit('MONTH_CODES_TO_STATE', response.data.data)
            })

        axios.get('/api/count/requests', {headers: auth.getAuthHeader()})
            .then(response => {
                commit('DRV_REQUESTS_TO_STATE', response.data.data)
            })
    },
    GET_CURRENT_USER({commit}) {
        return axios.get('/api/user/info', {headers: auth.getAuthHeader()})
            .then(response => {
                commit('CURRENT_USER_TO_STATE', response.data.data)
            })
    },
    GET_ACTIOIN_ITEMS({commit}) {
        return axios.get('/api/action', {headers: auth.getAuthHeader()})
            .then(response => {
                commit('ACTIOIN_ITEMS_TO_STATE', response.data.data)
            })
    },
    CLEAR_ALL({commit}) {
        commit('RESET_STATE')
    },
    STATUS_ACTIVE(commit, data) {
        return axios.post('/api/stat/active', data, {headers: auth.getAuthHeader()})

    },
    STATUS_REISSUE(commit, data) {
        return axios.post('/api/stat/reissue', data, {headers: auth.getAuthHeader()})

    },
    STATUS_REVOKE(commit, data) {
        return axios.post('/api/stat/revoke', data, {headers: auth.getAuthHeader()})

    },
    STATUS_BLOCK(commit, data) {
        return axios.post('/api/stat/block', data, {headers: auth.getAuthHeader()})

    },
    SET_BALANCE(commit, data) {
        // console.log({bin: bin, money: money, type: 'add'})
        return axios.post('/api/balance', data, {headers: auth.getAuthHeader()})
    },
    SET_FILTER({commit}, data) {
        commit('FILTER_TO_STATE', data)
    },
}

import Vue from 'vue'
import VueRouter from 'vue-router'
import License from "../views/License";
import Organisation from "../views/Organisation";
import Ecrs from "../views/Ecrs";
import Users from "../views/Users";
import NotFound from "../views/NotFoundPage";
import auth from '@/auth'
import store from '../store/store'
import Online from "../views/Online";

Vue.use(VueRouter)

const router = new VueRouter({
    // mode: 'history',
    // mode: 'abstract',
    routes: [
        {
            path: '/login',
            name: 'login',
            component: () => import(/* webpackChunkName: "about" */ '../views/Login.vue'),
        },
        {
            path: '/',
            name: 'home',
            component: () => import(/* webpackChunkName: "about" */ '../views/Home.vue'),
            beforeEnter: requireAuth
        },
        {
            path: '/codes',
            name: 'codes',
            component: License,
            props: true,
            beforeEnter: requireAuth,
            children: [
                {path: ':id', name: 'codes', component: License, props: {id: ':id'}}
            ]
        },
        {
            path: '/orgs',
            name: 'orgs',
            component: Organisation,
            props: true,
            beforeEnter: requireAuth,
            children: [
                {path: ':id', name: 'orgs', component: Organisation, props: {id: ':id'}}
            ]
        },
        {
            path: '/ecrs',
            name: 'ecrs',
            component: Ecrs,
            props: true,
            beforeEnter: requireAuth,
            children: [
                {path: ':id', name: 'ecrs', component: Ecrs, props: {id: ':id'}}
            ]
        },
        {
            path: '/online',
            name: 'online',
            component: Online,
            props: true,
            beforeEnter: requireAuth,
            // children: [
            //     {path: ':id', name: 'codes', component: License, props: {id: ':id'}}
            // ]
        },
        {
            path: '/users',
            name: 'users',
            component: Users,
            props: true,
            beforeEnter: requireAuth,
            children: [
                {path: ':id', name: 'users', component: Users}
            ]
        },
        {
            path: '/new',
            name: 'new',
            component: () => import(/* webpackChunkName: "about" */ '../views/NewNewRequest.vue'),
            beforeEnter: requireAuth
        },
        {
            path: '/bal',
            name: 'bal',
            component: () => import(/* webpackChunkName: "about" */ '../views/Balance.vue'),
            beforeEnter: requireAuth
        },
        {
            path: '/logout',
            name: 'logout',
            component: {
                beforeRouteEnter() {
                    store.dispatch("CLEAR_ALL");
                    auth.logout()
                }
            },
            // beforeEnter: requireAuth
        },
        {
            path: "*",
            component: NotFound,
        },
    ]

})

function requireAuth(to, from, next) {
    if (!auth.isAuthenticated()) {
        router.replace('/login')
    } else {
        next();
    }
}

export default router

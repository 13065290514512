<template>
  <div>
    <!-- Header -->
    <div class="header bg-gradient-info py-7 py-lg-8 pt-lg-9">
      <div class="container">
        <div class="header-body text-center mb-7">
          <div class="row justify-content-center">
            <div class="col xl-5 lg-6 md-6 px-5">
              <h1 >Ooops!</h1>
              <p class="text-lead">Page not found. Don't worry though, we have plenty of other pages to explore</p>

              <router-link to="/" class="font-weight-bold mt-5">Back to home</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'not-found'
};
</script>

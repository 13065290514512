<template>
  <b-card-text>
    <b-table striped hover small responsive="true" borderless outlined id="my-table"
             :items="USER_ITEMS"
             :fields="fields"
             :filter="filter"
             :filter-included-fields="filterOn"
             @filtered="onFiltered"
             :per-page="perPage"
             :current-page="currentPage"
             :busy="isBusy">
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong>Загрузка...</strong>
          </div>
        </template>
        <template #cell(id)="data">
          <router-link :to="'/users/'+data.item.id">{{data.item.id}}</router-link>
        </template>
        <template #cell(login)="data">
          <router-link :to="'/users/'+data.item.id">{{data.item.login}}</router-link> <b-icon v-if="data.item.isadmin" icon="award"></b-icon> <b-icon v-if="!data.item.isenable" icon="eye-slash"></b-icon>
        </template>
       <!--  <template #cell(klienttxt)="data">
          <router-link :to="'/orgs/'+data.item.biniin">{{data.item.klienttxt}}</router-link>
        </template> -->
    </b-table>
    <b-pagination first-number last-number size="sm" align="right" v-model="currentPage" :total-rows="rows" :per-page="perPage" aria-controls="my-table"></b-pagination>
  </b-card-text>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
export default {
  name: 'UsersList',
  data () {
    return {
      isBusy: false,

      perPage: 18,
      currentPage: 1,
      totalRows: 0,

      filterOn: ['login', 'bin', 'phone'],
      users: [],
      fields: [
        {key:'id', label:'#', sortable: true},
        {key:'login', label:'Наименование', sortable: true},
        {key:'bin', label:'БИН', sortable: true},
        {key:'phone', label:'Телефон'},
        {key:'balance', label:'Баланс'},
      ],
    }
  },
  props: {
    // idEcr: String,
    // org: {
    //   type: String,
    //   default: ''
    // }
  },

  computed: {
    ...mapGetters([
      'USER_ITEMS',
      'FILTER'
    ]),
    rows() {
      return this.USER_ITEMS == null ? 0 : this.USER_ITEMS.length
    },
    bin: function(){
      return this.binOrg
    },
    filter: {
      get() {
        return this.FILTER;
      },
    }
  },
  methods: {
    ...mapActions([
      'GET_USER_ITEMS'
    ]),
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
  },
  mounted() {
    this.GET_USER_ITEMS()
  },
}</script>

<template>
  <!--  <b-card :title="'Редактирование ['+orgItem.id+'] '+orgItem.name">-->
  <b-card :title="'Редактирование ['+ORG_ITEM.id+'] '+ORG_ITEM.name">
    <b-card-text>
      <b-row>
        <b-col class="mb-1" lg="2" order="1">
          <b-input-group prepend="ID">
            <!--            <b-form-input v-model.number="orgItem.id" readonly type="number"></b-form-input>-->
            <b-form-input v-model.number="ORG_ITEM.id" readonly type="number"></b-form-input>
          </b-input-group>
        </b-col>
        <b-col class="mb-1" lg="5" order="2">
          <b-input-group prepend="БИН/ИИН">
            <!--            <b-form-input v-model="orgItem.bin" type="number"></b-form-input>-->
            <b-form-input v-model="ORG_ITEM.bin" type="number"></b-form-input>
            <b-input-group-append is-text>
              <b-icon icon="search" @click="search"></b-icon>
            </b-input-group-append>
          </b-input-group>
        </b-col>
        <b-col class="mb-1" lg="4" order="3">
          <b-input-group prepend="Телефон">
            <!--            <b-form-input v-model="orgItem.phone" required type="tel"></b-form-input>-->
            <b-form-input v-model="ORG_ITEM.phone" required type="tel"></b-form-input>
          </b-input-group>
        </b-col>
        <b-col class="mb-1" lg="7" order="4">
          <b-input-group prepend="Email">
            <!--            <b-form-input v-model="orgItem.email" required type="email"></b-form-input>-->
            <b-form-input v-model="ORG_ITEM.email" required type="email"></b-form-input>
          </b-input-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="mb-1" lg="12" order="1">
          <b-input-group prepend="Наименование">
            <!--            <b-form-textarea v-model="orgItem.name" required></b-form-textarea>-->
            <b-form-textarea v-model="ORG_ITEM.name" required></b-form-textarea>
          </b-input-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col class="mb-1" lg="12" order="1">
          <b-button variant="primary" @click="click">{{ okBtnTitle }}</b-button>
        </b-col>
      </b-row>
    </b-card-text>
  </b-card>

</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: 'Organisation',
  data() {
    return {
      isBusy: false,
    }
  },
  props: {
    // orgItem: {
    //   type: Object,
    //   default() {
    //     return {id: 0}
    //   }
    // },
    okBtnTitle: {
      type: String,
      default: 'Ok'
    }
  },
  computed: {
    ...mapGetters([
      'ORG_ITEM'
    ]),
  },
  methods: {
    ...mapActions([
      'GET_ORG_ITEM',
      'GET_ORGBYBIN_ITEM'
    ]),
    search: function () {
      this.GET_ORGBYBIN_ITEM(this.ORG_ITEM.bin)
          .catch((error) => {
            if (error.response.status === 401) {
              this.$router.push('/logout')
            }
          })
      console.log(this.ORG_ITEM.bin)
    },
    click: function () {
      this.$emit('clickOkBtn')
    }
  },
  mounted() {
    this.GET_ORG_ITEM(this.$route.params.id)
        .catch((error) => {
          if (error.response.status === 401) {
            this.$router.push('/logout')
          }
        })
  },
}</script>

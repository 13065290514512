<template>
  <div id="app" class="wrapper">

    <nav v-if="isAuthenticated()" id="sidebar" :class="{'sidebar':true, 'collapsed': showSidebar}">
      <div class="sidebar-content js-simplebar">
        <router-link class="sidebar-brand" to="/">
          <img alt="" class="d-inline-block align-top" height="30" src="@/assets/favicons/safari-pinned-tab.svg"
               width="30"> <span class="align-middle">License SVR</span></router-link>
        <ul class="sidebar-nav">
          <li class="sidebar-header">
            Меню
          </li>
          <template v-if="isAuthenticated()">
            <li v-for="item in links" :key="item.id"
                :class="{'sidebar-item':true, 'active': '/'+$route.name===item.path}">
              <router-link :to="item.path" class="sidebar-link">
                <b-icon :icon="item.icon"></b-icon>
                <span class="align-middle">{{ item.name }}</span>
              </router-link>
            </li>
          </template>
        </ul>
      </div>
    </nav>

    <div class="main">

      <nav v-if="isAuthenticated()" class="navbar navbar-expand navbar-light navbar-bg mb-2">
        <a class="d-flex" @click="collaps">
          <b-icon aria-hidden="true" class="mr-2 align-self-center" font-scale="2" icon="layout-sidebar-inset"
                  variant="dark"></b-icon>
        </a>
        <form class="d-none d-sm-inline-block">
          <div class="input-group input-group-navbar">
            <input aria-label="Search" class="form-control"
                   placeholder="Search…"
                   v-model="filter"
                   type="search"
            >
            <button class="btn" type="button"><i class="align-middle" data-feather="search"></i></button>
          </div>
        </form>
        <Balance/>
      </nav>

<!--      <b-container>-->
        <keep-alive>
          <router-view/>
        </keep-alive>
<!--      </b-container>-->

    </div>
  </div>
</template>

<script>
import Balance from '@/components/Balance'
import auth from '@/auth'
import {mapActions, mapGetters} from "vuex"

export default {
  head: {
    title: {
      inner: 'License SVR',
      complement: 'СЦ Пульсар'
    },
    meta: [
      {name: 'viewport', content: 'width=device-width, initial-scale=1, shrink-to-fit=no'},
      {name: 'application-name', content: 'Драйвер ККМ Daisy'},
      {name: 'description', content: 'Интерфейс для управления ККМ Daisy и его настройки'},
      {name: 'msapplication-TileColor', content: '#da532c'},
    ],
    link: [
      {r: 'icon', h: require('@/assets/favicons/favicon-16x16.png'), t: "image/x-icon"},
      {rel: 'canonical', href: 'http://scp.kz', id: 'canonical'},
      {rel: 'author', href: 'SC Pulsar', undo: false}, // undo property - not to remove the element
      {r: 'apple-touch-icon', h: require('@/assets/favicons/apple-touch-icon.png'), sz: '180x180'},
      {r: 'icon', h: require('@/assets/favicons/favicon-32x32.png'), sz: '32x32', t: 'image/png'},
      {r: 'icon', h: require('@/assets/favicons/favicon-16x16.png'), sz: '16x16', t: 'image/png'},
      {r: 'mask-icon', h: require('@/assets/favicons/safari-pinned-tab.svg'), color: "#5bbad5"},
    ],
  },
  data() {
    return {
      showSidebar: false,
      links: [
        {
          icon: "list",
          name: 'Лицензии',
          path: '/codes',
        },
        {
          icon: "grid",
          name: 'Клиенты',
          path: '/orgs',
        },
        {
          icon: "layers",
          name: 'Серийники',
          path: '/ecrs',
        },
        {
          icon: "wifi",
          name: 'online',
          path: '/online',
        },
        {
          icon: "plus",
          name: 'Новая заявка',
          path: '/new',
        },
        {
          icon: "cash",
          name: 'Баланс',
          path: '/bal',
        },
        {
          icon: "people",
          name: 'Пользователи',
          path: '/users',
        },
        {
          icon: "door-open",
          name: 'Выход',
          path: '/logout',
        },
      ]
    }
  },
  components: {
    Balance
  },
  methods: {
    ...mapActions([
      'CLEAR_ALL',
      'SET_FILTER'
    ]),
    logout() {
      this.CLEAR_ALL()
      auth.logout(this)
    },
    isAuthenticated() {
      let isAuth = auth.isAuthenticated()
      if (isAuth) {
        // this.GET_CURRENT_USER()
      }
      return isAuth
    },

    collaps() {
      this.showSidebar = !this.showSidebar
    },
  },
  computed: {
    ...mapGetters([
      'FILTER'
    ]),
    filter: {
      get() {
        return this.FILTER;
      },
      set(data) {
        this.SET_FILTER(data)
      }
    }
  },
  mounted() {
  }
}
</script>
<template>
  <div>

    <!--    :orgItem="currentItem"-->
    <Organisation
        okBtnTitle='Сохранить'
        @clickOkBtn="saveOrganisaton"
    />

    <b-card>
      <EcrsListByOrg :org="$route.params.id"/>
    </b-card>
  </div>

</template>

<script>
import Organisation from "@/components/Organisation/Organisation"
import EcrsListByOrg from "@/components/Ecr/EcrsListByOrg"
import {mapActions, mapGetters} from "vuex";

export default {
  name: 'Edit',
  data() {
    return {}
  },
  components: {
    Organisation,
    EcrsListByOrg
  },
  computed: {
    ...mapGetters([
      'ECR_ITEMS',
      'ORG_ITEM'
    ]),
    currentItem: function () {
      // console.log(this.ORG_ITEMS)
      // for (let i = 0; i < this.ORG_ITEMS.length; i++) {
      //   if (this.ORG_ITEMS[i].id === this.$route.params.id) {
      //     // console.log('found: ' + i)
      //     // console.log('found: ' + this.$route.params.id)
      //     // this.GET_ECR_ITEMS(i)
      //     return this.ORG_ITEMS[i]
      //   }
      // }
      // return {}
      // console.log(this.ORG_ITEM)
      return this.ORG_ITEM
    },
  },
  methods: {
    ...mapActions([
      'SAVE_ORG_TO_DB',
      'GET_ORG_ITEM',
      // 'GET_ECR_ITEM'
    ]),
    saveOrganisaton: function () {
      this.SAVE_ORG_TO_DB(this.currentItem)
          .then(() => {
            this.$router.back()
          })
          .catch(error => {
            this.showError('Ошибка запроса', error.message)
          })
    },
  },
  mounted() {
    // this.GET_ORG_ITEM(this.$route.params.id)
    //     .then(() => {
    //       console.log(this.ORG_ITEM)
    //
    //     })
  }
}</script>

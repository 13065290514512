import Vue from "vue"
import Vuex from "vuex"

import mutations from "./mutations"
import actions from "./actions"
import getters from "./getters"

Vue.use(Vuex)

const getDefaultState = () => {
    return {
        codeItems: [],
        onlineItems: [],
        ecrItem: [],
        ecrItems: [],
        orgItem: [],
        orgItems: [],
        balanceItems: [],
        userItems: [],
        modelItems: [],
        monthCodes: {},
        drvReguests: {},
        graphData: {},
        currUser: {},
        actions: [],
        filter: ''
    }
}
// initial state
const state = getDefaultState()

const store = new Vuex.Store({
    state,
    mutations,
    actions,
    getters,

})

export default store;
<template>
  <b-row>
    <b-col cols="12">
      <b-card no-body>
<!--        <template #header>-->
        <template>
          <b-button-toolbar key-nav>
            <b-button-group v-if="$route.params.id" class="mx-1">
              <b-button variant="outline-dark" @click="$router.back()">
                <b-icon icon="chevron-left"></b-icon>
              </b-button>
            </b-button-group>
            <b-button-group v-else class="mx-1">
              <router-link :to="'/orgs/0'">
                <b-button variant="outline-dark">
                  <b-icon icon="plus"></b-icon>
                </b-button>
              </router-link>
              <b-button variant="outline-dark" @click="GET_ORG_ITEMS">
                <b-icon icon="arrow-repeat"></b-icon>
              </b-button>
            </b-button-group>
          </b-button-toolbar>
        </template>

        <template v-if="$route.params.id">
          <!--            <OrganisationEdit :currItem="data.item.id"/>-->
          <!--            <OrganisationEdit :currItem="$route.params.id"/>-->
          <OrganisationEdit/>
        </template>

        <template v-else>
          <b-table id="my-table" :busy="isBusy"
                   :current-page="currentPage"
                   :fields="fields"
                   :filter="filter"
                   :filter-included-fields="filterOn"
                   @filtered="onFiltered"
                   :items="ORG_ITEMS"
                   :per-page="perPage"
                   borderless
                   hover
                   outlined
                   responsive="true" small striped>
            <template #table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner class="alignmake-middle"></b-spinner>
                <strong>Загрузка...</strong>
              </div>
            </template>
            <template #cell(bin)="data">
              <!--                  <router-link :to="'/orgs/'+data.item.bin">{{data.item.bin}}</router-link>-->
              <router-link :to="'/orgs/'+data.item.id">{{ data.item.bin }}</router-link>
            </template>
            <template #cell(phone)="data">
              <a :href="'tel:'+data.item.phone">{{ data.item.phone }}</a>
            </template>
            <template #cell(email)="data">
              <a :href="'mailto:'+data.item.email+'?subject=Сервер лицензий'">{{ data.item.email }}</a>
            </template>
          </b-table>
          <b-pagination v-model="currentPage" :per-page="perPage" :total-rows="rows" align="right" aria-controls="my-table" first-number
                        last-number size="sm"></b-pagination>
          <!-- </b-card-text> -->
        </template>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import OrganisationEdit from "@/components/Organisation/Edit"
import {mapActions, mapGetters} from "vuex"

export default {
  name: 'organisation',
  data() {
    return {
      isBusy: false,

      perPage: 18,
      currentPage: 1,
      totalRows: 0,

      filterOn: ['bin', 'name', 'phone'],
      fields: [
        {key: 'bin', label: 'БИН/ИИН', sortable: true},
        {key: 'name', label: 'Наименование', sortable: true},
        {key: 'phone', label: 'Телефон'},
        {key: 'email', label: 'eMail'},
      ],
    }
  },
  components: {
    OrganisationEdit
  },
  // props: {
  //   filter: String
  // },
  computed: {
    ...mapGetters([
      'ORG_ITEMS',
      'FILTER'
    ]),
    rows() {
      if (this.filter !== '') {
        return this.totalRows
      }
      return this.ORG_ITEMS == null ? 0 : this.ORG_ITEMS.length
    },
    filter: {
      get() {
        return this.FILTER;
      },
    }
  },
  methods: {
    ...mapActions([
      'GET_ORG_ITEMS'
    ]),
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    }
  },
  mounted() {
    this.GET_ORG_ITEMS()
        .catch((error) => {
          if (error.response.status === 401) {
            this.$router.push('/logout')
          }
        })
  }
}</script>

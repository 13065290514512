export default {
	methods: {
		showToast: function(variant = null, titl = null, msg = null) {
			this.$bvToast.toast(msg, {
				title: titl,
				variant: variant,
				solid: true
			})
		},
		showSuccess: function(titl = null, msg = null) {
			this.showToast('success', titl, msg)
		},
		showError: function(titl = null, msg = null) {
			this.showToast('danger', titl, msg)
		},
		showWarning(titl = null, msg = null) {
			this.showToast('warning', titl, msg)
		},
		showInfo(titl = null, msg = null) {
			this.showToast('info', titl, msg)
		},
	}
}

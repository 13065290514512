<template>
  <b-row>
    <b-col cols="12">
      <b-card no-body>
<!--        <template #header>-->
        <template>
          <b-button-toolbar key-nav>
            <b-button-group v-if="$route.params.id" class="mx-1">
              <b-button variant="outline-dark" @click="$router.back()">
                <b-icon icon="chevron-left"></b-icon>
              </b-button>
            </b-button-group>
            <b-button-group v-else class="mx-1">
              <router-link :to="'/ecrs/0'">
                <b-button variant="outline-dark">
                  <b-icon icon="plus"></b-icon>
                </b-button>
              </router-link>
            </b-button-group>
          </b-button-toolbar>
        </template>

        <template v-if="$route.params.id">
          <!--          <EcrEdit :ecrItem="$route.params.id"/>-->
          <EcrEdit/>
        </template>

        <template v-else>
          <EcrsList/>
        </template>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import EcrsList from "@/components/Ecr/EcrsList"
import EcrEdit from "@/components/Ecr/EcrEdit"

export default {
  name: 'Ecrs',
  // head: {
  //   title: {
  //     inner: 'EcrsList',
  //   }
  // },
  data() {
    return {}
  },
  components: {
    EcrsList,
    EcrEdit
  }
}</script>

<template>
  <div>
    <Ecr
        :ecrItem="currentItem"
        okBtnTitle="Сохранить"
        @clickOkBtn="saveEcr"
    />
    <!--    <b-card>-->
    <!--       <EcrsList :ecr="$route.params.id"/> -->
    <!--    </b-card>-->
  </div>

</template>

<script>
import Ecr from "@/components/Ecr/EcrCard"
import Toasts from '@/mixins/toasts';
import {mapActions, mapGetters} from "vuex";

export default {
  name: 'EcrEdit',
  mixins: [Toasts],
  data() {
    return {}
  },
  // props: {
  //   ecrItem: String,
  // },
  components: {
    Ecr
  },
  computed: {
    ...mapGetters([
      'ECR_ITEM'
    ]),
    currentItem: function () {
      // if (this.ECR_ITEMS) {
      //   // return this.ECR_ITEMS
      //   for (let i = 0; i < this.ECR_ITEMS.length; i++) {
      //     if (this.ECR_ITEMS[i].id == this.$route.params.id) {
      //       return this.ECR_ITEMS[i]
      //     }
      //   }
      // }
      // return {}
      return this.ECR_ITEM
    },
  },
  methods: {
    ...mapActions([
      'GET_ECR_ITEM',
      'SAVE_ECR_TO_DB'
    ]),

    saveEcr: function () {
      this.SAVE_ECR_TO_DB(this.currentItem)
          .then(() => {
            this.$router.back()
          })
          .catch(error => {
            this.showError('Ошибка запроса', error.message)
            // console.log(error)
          })
    }
  },

  mounted() {
    // console.log("!!!! mounted " + this.$route.params.id)
    if (this.$route.params.id) {
      this.GET_ECR_ITEM(this.$route.params.id)
          .catch((error) => {
            if (error.response.status === 401) {
              this.$router.push('/logout')
            }
          })
    }
  },

}</script>

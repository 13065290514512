<template>
  <b-row>
    <b-col cols="12">
        <b-card no-body>
          <template #header>
            <b-button-toolbar key-nav>
              <b-button-group v-if="$route.params.id" class="mx-1">
                <b-button variant="outline-dark" @click="$router.back()"><b-icon icon="chevron-left"></b-icon></b-button>
              </b-button-group>
              <b-button-group v-else class="mx-1">
                
                <router-link :to="'/users/0'"><b-button variant="outline-dark"><b-icon icon="plus"></b-icon></b-button></router-link>
              </b-button-group>
            </b-button-toolbar>
          </template>

          <template v-if="$route.params.id">
            <UserEdit :usrId="$route.params.id"/>
          </template>
          
          <template v-else>
            <UsersList/>
          </template>
        </b-card>
    </b-col>
  </b-row>
</template>

<script>
  import UsersList from "@/components/UsersList"
  import UserEdit from "@/components/UserEdit"
export default {
  name: 'Users',
  // head: {
  //   title: {
  //     inner: 'EcrsList',
  //   }
  // },
  data () {
    return {
    }
  },
  components: {
    UsersList,
    UserEdit
  }
}</script>

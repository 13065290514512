export default {
    CODE_ITEMS(state) {
        return state.codeItems
    },
    ONLINE_ITEMS(state) {
        return state.onlineItems
    },
    ECR_ITEM(state) {
        return state.ecrItem
    },
    ECR_ITEMS(state) {
        return state.ecrItems
    },
    ORG_ITEM(state) {
        return state.orgItem
    },
    ORG_ITEMS(state) {
        return state.orgItems
    },
    BALANCE_ITEMS(state) {
        return state.balanceItems
    },
    USER_ITEMS(state) {
        return state.userItems
    },
    MODEL_ITEMS(state) {
        return state.modelItems
    },
    MONTH_CODES(state) {
        return state.monthCodes
    },
    DRV_REQ_CODES(state) {
        return state.drvReguests
    },
    GRAPH_DATA_CODES(state) {
        return state.graphData
    },
    CURRENT_USER(state) {
        return state.currUser
    },
    ACTION_ITEMS(state) {
        return state.actions
    },
    FILTER(state) {
        return state.filter
    }
}
<template>
  <b-card-text>
    <b-table id="my-table" :busy="isBusy"
             :current-page="currentPage"
             :fields="fields"
             :filter="filter"
             :filter-included-fields="filterOn"
             @filtered="onFiltered"
             :items="ECR_ITEMS"
             :per-page="perPage" borderless hover outlined
             responsive="true" small striped>
      <template #table-busy>
        <div class="text-center text-danger my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong>Загрузка...</strong>
        </div>
      </template>
      <template #cell(Model.name)="data">
        {{ data.item.Model.name }}
      </template>
      <template #cell(znm)="data">
        <router-link :to="'/ecrs/'+data.item.id">{{ data.item.znm }}</router-link>
      </template>
      <!--      <template #cell(Klient.name)="data">-->
      <!--        <router-link :to="'/orgs/'+data.item.Klient.id">{{ data.item.Klient.name }}</router-link>-->
      <!--      </template>-->
    </b-table>
    <b-pagination v-model="currentPage" :per-page="perPage" :total-rows="rows" align="right" aria-controls="my-table"
                  first-number
                  last-number size="sm"></b-pagination>
  </b-card-text>
</template>

<script>
import Toasts from '@/mixins/toasts';
import {mapActions, mapGetters} from "vuex";

export default {
  name: 'EcrsList',
  mixins: [Toasts],
  data() {
    return {
      isBusy: false,

      perPage: 18,
      currentPage: 1,
      totalRows: 0,

      filterOn: ['Klient', 'znm', 'rnm'],
      fields: [
        {key: 'id', label: '#', sortable: true},
        {key: 'Model.name', label: 'Модель', sortable: true},
        {key: 'znm', label: 'ЗНМ', sortable: true},
        {key: 'rnm', label: 'РНМ', sortable: true},
        {key: 'Klient.name', label: 'Клиент'},
      ],
    }
  },
  props: {
    idEcr: String,
    org: {
      type: String,
      default: ''
    }
  },

  computed: {
    ...mapGetters([
      'ECR_ITEMS',
      'MODEL_ITEMS',
      'FILTER'
    ]),
    rows() {
      if (this.filter !== '') {
        return this.totalRows
      }
      return this.ECR_ITEMS == null ? 0 : this.ECR_ITEMS.length
    },
    bin: function () {
      return this.org
    },
    filter: {
      get() {
        return this.FILTER;
      },
    }
  },
  methods: {
    ...mapActions([
      'GET_ECR_ITEMS',
      'GET_MODEL_ITEMS',
      // 'CLEAR_ECR_ITEMS'
    ]),
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    }
  },
  mounted() {
    this.GET_ECR_ITEMS()
        .catch((error) => {
          if (error.response.status === 401) {
            this.$router.push('/logout')
          }
        })
  },

}</script>

const getDefaultState = () => {
    return {
        codeItems: [],
        ecrItem: {},
        ecrItems: [],
        orgItem: {},
        orgItems: [],
        userItems: [],
        modelItems: [],
        monthCodes: {},
        drvReguests: {},
        graphData: {},
        currUser: {},
        actions: [],
        filter: ''
    }
}

export default {
    CODE_ITEMS_TO_STATE: (state, data) => {
        state.codeItems = data
    },
    ONLINE_ITEMS_TO_STATE: (state, data) => {
        state.onlineItems = data
    },
    ECR_ITEM_TO_STATE: (state, data) => {
        state.ecrItem = data
    },
    ECR_ITEMS_TO_STATE: (state, data) => {
        state.ecrItems = data
    },
    ORG_ITEM_TO_STATE: (state, data) => {
        state.orgItem = data
    },
    ORG_ITEMS_TO_STATE: (state, data) => {
        state.orgItems = data
    },
    BALANCE_ITEMS_TO_STATE: (state, data) => {
        state.balanceItems = data
    },
    USER_ITEMS_TO_STATE: (state, data) => {
        state.userItems = data
    },
    MODEL_ITEMS_TO_STATE: (state, data) => {
        state.modelItems = data
    },
    MONTH_CODES_TO_STATE: (state, data) => {
        state.monthCodes = data
    },
    DRV_REQUESTS_TO_STATE: (state, data) => {
        state.drvReguests = data
    },
    GRAPH_DATA_TO_STATE: (state, data) => {
        state.graphData = data
    },
    CURRENT_USER_TO_STATE: (state, data) => {
        state.currUser = data
    },
    ACTIOIN_ITEMS_TO_STATE: (state, data) => {
        state.actions = data
    },
    // RESET_ECR_ITEMS: (state) => {
    //     state.ecrItems = []
    // },
    RESET_STATE: (state) => {
        Object.assign(state, getDefaultState())
    },
    FILTER_TO_STATE: (state, data) => {
        state.filter = data
    }
}

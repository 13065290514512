<template>
  <b-row>
    <b-col class="mb-1" lg="3" order="2">
      ЗНМ:
      <router-link :to="'/ecrs/'+ecrItem.id">{{ ecrItem.znm }}</router-link>
    </b-col>
    <b-col class="mb-1" lg="3" order="3">
      РНМ:
      <router-link :to="'/ecrs/'+ecrItem.id">{{ ecrItem.rnm }}</router-link>
      <!--          Телефон: <a :href="'tel:'+orgItem.phone">{{orgItem.phone}}</a>-->
    </b-col>
    <b-col class="mb-1" lg="6" order="4">
      Модель:
      <router-link :to="'/ecrs/'+ecrItem.id">{{ currentModel.name }}</router-link>
    </b-col>
  </b-row>

</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "ViewEcrCard",
  props: {
    ecrItem: {
      type: Object,
      default() {
        return {id: 0}
      }
    },
    okBtnTitle: {
      type: String,
      default: 'Ok'
    }
  },
  computed: {
    ...mapGetters([
      'MODEL_ITEMS'
    ]),
    currentModel: function () {
      for (let i = 0; i < this.MODEL_ITEMS.length; i++) {
        if (this.MODEL_ITEMS[i].id === this.ecrItem.model) {
          return this.MODEL_ITEMS[i]
        }
      }
      return {}
    },

  },
  methods: {
    ...mapActions([
      // 'GET_ECR_ITEMS',
      'GET_MODEL_ITEMS',
    ]),
  },
  mounted() {
    // this.GET_ECR_ITEMS()
    this.GET_MODEL_ITEMS()
        .catch((error) => {
          if (error.response.status === 401) {
            this.$router.push('/logout')
          }
        })
  }
}
</script>

<template>
  <b-row>
    <b-row>
      <template v-if="((status === 1)||(status === 2)||(status === 3)||(status === 9))">
        <b-col class="mb-1" lg="3" order="1">
          <b-button block variant="success" @click="active">Активировать</b-button>
        </b-col>
      </template>
      <template v-if="((status === 5)||(status === 6))">
        <b-col class="mb-1" lg="3" order="1">
          <b-button block variant="primary" @click="reissue">Перевыпустить</b-button>
        </b-col>
      </template>
      <template v-if="((status === 4)||(status === 7))">
        <b-col class="mb-1" lg="3" order="1">
          <b-button block variant="warning" @click="revoke">Отозвать
          </b-button>
        </b-col>
      </template>
      <template v-if="((status === 1)||(status === 4)||(status === 7))">
        <b-col class="mb-1" lg="3" order="1">
          <b-button block variant="danger" @click="block">Блокировать
          </b-button>
        </b-col>
      </template>
    </b-row>
  </b-row>

</template>

<script>
import Toasts from '@/mixins/toasts';

export default {
  mixins: [Toasts],

  name: "CodeActions",
  props: {
    status: {
      type: Number,
      default: 0
    },
  },
  methods: {
    active() {
      this.$emit("active")
    },
    reissue() {
      this.$emit("reissue")
    },
    revoke() {
      this.$emit("revoke")
    },
    block() {
      this.$emit("block")
    }
  }
}
</script>

<style scoped>

</style>
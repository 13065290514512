<template>
  <div>
    <b-card :title="'Редактирование ['+user.id+']'">
      <b-card-text>
        <b-row>
          <b-col lg="2" order="1" class="mb-1">
            <b-input-group prepend="ID">
              <b-form-input type="text" v-model.number="user.id" readonly></b-form-input>
            </b-input-group>
          </b-col>
          <b-col lg="5" order="2" class="mb-1">
            <b-input-group prepend="БИН/ИИН">
              <b-form-input type="number" v-model="user.bin"></b-form-input>
              <b-input-group-append is-text>
                <b-icon icon="search"></b-icon>
              </b-input-group-append>
            </b-input-group>
          </b-col>
          <b-col lg="5" order="3" class="mb-1">
            <b-input-group prepend="Телефон">
              <b-form-input type="tel" required v-model="user.phone"></b-form-input>
            </b-input-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col lg="4" order="1" class="mb-1">
            <b-input-group prepend="Логин">
              <b-form-input type="text" v-model="user.login"></b-form-input>
            </b-input-group>
          </b-col>
          <b-col lg="4" order="2" class="mb-1">
            <b-input-group prepend="Пароль">
              <b-form-input type="password" v-model="user.passwd"></b-form-input>
            </b-input-group>
          </b-col>
          <b-col lg="4" order="2" class="mb-1">
            <b-input-group prepend="Подтверждение">
              <b-form-input type="password" v-model="passwd2"></b-form-input>
            </b-input-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col lg="4" order="1" class="mb-1">
            <b-form-checkbox v-model="user.isadmin" switch>
              Это администратор
            </b-form-checkbox>
          </b-col>
          <b-col lg="4" order="2" class="mb-1">
            <b-form-checkbox v-model="user.isenable" switch>
              Доступ в систему
            </b-form-checkbox>
          </b-col>
        </b-row>

        <b-row>
          <b-col lg="12" order="1" class="mb-1">
            <b-button class="mr-2" variant="primary" @click="saveUser" :disabled="!passwdCorrect">Сохранить</b-button>
            <b-button variant="danger" @click="deleteUser">Удалить</b-button>
          </b-col>
        </b-row>
      </b-card-text>
    </b-card>

    <b-card>
      <!-- <UsersList :user="$route.params.id"/> -->
    </b-card>
  </div>

</template>

<script>
  // import UsersList from "@/components/UsersList"

import auth from "@/auth";

  export default {
  name: 'UserEdit',
  data () {
    return {
      isBusy: false,
      user: {},
      passwd2: ''
    }
  },
  components: {
    // UsersList
  },
  props: {
    usrId: String
  },
  methods: {
    saveUser: function() {
      this.axios.post('/api/user', this.user, { headers: auth.getAuthHeader() })
        .then(() => {this.$router.back()})
        .catch(error => {
         this.showError('Ошибка запроса', error.message)
         console.log(error)
        })
    },
    deleteUser: function() {
      this.axios.delete('/api/user/'+this.usrId, { headers: auth.getAuthHeader() })
        .then(() => {this.$router.back()})
        .catch(error => {
         this.showError('Ошибка запроса', error.message)
         console.log(error)
        })
    },
    getUser: function() {
      this.isBusy = true
      this.axios.get('/api/user/'+this.usrId, { headers: auth.getAuthHeader() })
        .then(response => {
          this.user = response.data
        })
        .catch(error => {
          this.showError('Ошибка запроса', error.message)
          console.log(error)
        })
        .finally(this.isBusy = false)
    },
  },
  computed: {
    passwdCorrect: function() {
      return this.user.passwd === this.passwd2
    }
  },

  mounted() {
    this.getUser()
  },
}</script>
